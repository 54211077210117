








































import { Component, Vue } from "vue-property-decorator";
import MainSidebarItem from "@/components/MainSidebar/MainSidebarItem.vue";

import fscLogo from "@/assets/FSC_Hand_artemis.svg";
import lmsLogo from "@/assets/drivebuzz_Bee_artemis_4c.svg";
import ActionButton from "@/components/Button/ActionButton.vue";
import { namespace } from "vuex-class";

const MainTabsModule = namespace("main-tabs");
@Component({
  components: { ActionButton, MainSidebarItem },
})
export default class MainSidebar extends Vue {
  public name = "MainSidebar";

  public fscLogo: string = fscLogo;
  public lmsLogo: string = lmsLogo;

  @MainTabsModule.Getter("getActiveContext")
  private getActiveContextAction: any;

  public signOut(): void {
    this.$router.push({ name: "Logout" });
  }

  public setFSCSidebarOn(): void {
    this.$emit("fsc-sidebar-click");
  }

  public setLMSSidebarOn(): void {
    this.$emit("lms-sidebar-click");
  }

  public get backgroundClass(): string {
    if (this.getActiveContextAction == "lms") {
      return "lms-background";
    } else if (this.getActiveContextAction == "fsc") {
      return "fsc-background";
    }
    return "default-background";
  }
}
